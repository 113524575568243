/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Review from "../components/reviews/Review";
import T40Container from "../layout/Container";
import BaseButton from "../components/widgets/ui/button/BaseButton";
import { NavLink, useLocation } from "react-router-dom";
import { FaThumbsUp } from "react-icons/fa6";
import close from '../assets/x-circle.svg';
import { FaChevronDown } from "react-icons/fa6";
import baseURL, { brandID } from "../api/ApiConfig";
import axios from "axios";
import { motion } from 'framer-motion';
import ApiFetcher from "../utils/api";
import { toast } from "react-toastify";
import { brandName } from "../api/ApiConfig";
import { getDynamicTexts } from "../utils/dynamic/textUtils";
import ReviewSidebar from "../components/reviews/ReviewSidebar";


const ReviewsPage = () => {
    const [hide, setHide] = useState<boolean>(false);
    const [allReviews, setAllReviews] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastPage, setLastPage] = useState<number>(0);
    const [resultPage, setResultPage] = useState<number>(1);
    const [profile, setProfile] = useState([]);

    const dynamicText = getDynamicTexts();
    // console.log(resultPage, lastPage)
    const location = useLocation();

    const { key } = location.state || {};

    const getAllReviews = async (page: number) => {
        try {
            setLoading(true);            
            // await axios.get(`${baseURL}/v1/intercity/reviews?service_type=${'ticket'}&per_page=10&page=${page}&brand_id=${brandID}`)
            await axios.get(`${baseURL}/v1/intercity/reviews?service_type=${'ticket'}&per_page=10&page=${page}`)
                .then((res: any) => {
                    console.log("rev response", res);
                    setLastPage(res?.data?.meta?.last_page);
                    // setAllReviews((prev: any) => [...prev, ...res?.data?.data]);
                    setAllReviews(res?.data?.data);
                })

        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false);
        }
    }

    const loadMoreResults: any = () => {
        window.scrollTo(0, 0);

        setResultPage((prev: number) => {
            const newResultPage = prev + 1;
            getAllReviews(newResultPage);
            return newResultPage;
        });
    }

    useEffect(() => {
        getAllReviews(resultPage);
    }, [])


    return (
        <main className='pb-16'>
            <div className="hidden bg-gray-900 px-6 lg:px-0">
                <T40Container>
                    <motion.div
                        initial={{
                            y: 50,
                            opacity: 0,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            // damping: 70,
                            duration: 4,
                        }}
                        whileInView={{
                            y: 0,
                            opacity: 1
                        }}
                        className="w-full py-4 flex flex-row items-center justify-center gap-3"
                    >
                        <div className="bg-pink-500 px-2 rounded">
                            <span className="text-white font-black text-xs lg:text-sm">
                                NOTICE
                            </span>
                        </div>

                        <span className="text-xs lg:text-sm font-medium text-blue-50">
                            {dynamicText.ticketTitle}
                        </span>
                    </motion.div>
                </T40Container>
            </div>

            <header
                className="sm:h-[40vh] md:h-[60vh] xl:h-[80vh] relative review-img"
            >
                <T40Container className="relative">
                    <motion.div
                        initial={{
                            x: -50,
                            opacity: 0,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            // damping: 70,
                            duration: 3,
                        }}
                        whileInView={{
                            x: 0,
                            opacity: 1
                        }}
                        className="pt-[18px] md:pt-[80px] w-[107px] sm:w-[269px]"
                    >
                        <h2 className="text-base md:text-3xl font-bold leading-[24px] md:leading-[45px] mb-12 text-[#050D1B]">
                            Track Record of Excellence:
                        </h2>

                        <h3 className="text-[10px] md:text-xl leading-[15px] md:leading-[30px] font-medium text-[#050D1B]">
                            Read the Reviews That Paint a Picture of Our Service!
                        </h3>
                    </motion.div>
                </T40Container>

                {key && <div className={`${hide ? "hidden" : "absolute top-2 md:top-4 right-4 md:right-20 flex items-center w-fit px-2.5 md:px-4 py-1 bg-[#F3FAF7] rounded-lg border border-[#def7ec] "}`} >
                    <FaThumbsUp className="w-2.5 md:w-4 h-2.5 md:h-4 text-green-500" />

                    <div className="px-2.5 md:px-4 text-[8px] md:text-lg leading-[27px] text-gray-900">
                        <p className="font-bold">Thanks for choosing {brandName}!</p>
                        <p className="font-medium">
                            Your review has been recorded to help us serve you better!
                        </p>
                    </div>

                    <div>
                        <img className="w-2.5 md:w-4 h-2.5 md:h-4" onClick={() => setHide(true)} src={close} alt="close review" />
                    </div>
                </div>}

                <NavLink
                    to="/write-reviews"
                    className="absolute block text-center bottom-[19px] md:bottom-[40px] right-4 md:right-[80px] px-4 md:px-5 text-white bg-[#1A56DB]
                     py-2 lg:px-6 lg:py-3.5 rounded-lg text-xs lg:text-base font-medium lg:font-['Inter']
                    "
                >
                    Write a review
                </NavLink>
            </header>

            <section className="mt-10">
                <T40Container>
                    <h1 className="font-bold text-lg md:text-[36px] leading-[27px] md:leading-[54px] text-[#050d1b]">
                        Feedbacks from our customers
                    </h1>

                    <div className="relative mt-4 space-y-3">
                        {
                            loading ?
                                <div>
                                    loading reviews . . .
                                </div> :
                                allReviews.length === 0 ?
                                    <p className="text-lg font-medium">
                                        No reviews available . . .
                                    </p>
                                    :
                                    allReviews.length > 0 ?
                                        <div className="grid grid-cols-10 gap-x-4 ">
                                            {/* <div className="col-span-3">
                                                <ReviewSidebar />
                                            </div> */}

                                            <div className="col-span-10 space-y-4 lg:space-y-6">
                                                {allReviews?.map(({ first_name, last_name, from_city: { name: origin }, travel_date, brand: { rating }, to_city: { name: destination }, description, responses }: any) => (
                                                    <Review
                                                        name={`${first_name} ${last_name}`}
                                                        date={travel_date}
                                                        ratings={rating}
                                                        origin={origin}
                                                        destination={destination}
                                                        review={description}
                                                        posterResponse={responses[0]}
                                                    />
                                                ))}
                                            </div>
                                        </div> :
                                        <p className="text-lg text-red-400 font-medium">
                                            Unable to load reviews . . .
                                        </p>
                        }

                    </div>
                    {
                        resultPage < lastPage &&
                        <div className="mt-4 flex justify-center items-center">
                            <BaseButton onClick={loadMoreResults} title="Read more" icon={<FaChevronDown />} />
                        </div>
                    }

                </T40Container>
            </section>
        </main>
    )
}

export default ReviewsPage