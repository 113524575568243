import React, { useState } from 'react';
import { FaThumbsUp } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import facebook from '../../assets/facebook-icon.svg';
import twitter from '../../assets/twitter-icon.svg';
import telegram from '../../assets/telegram-icon.svg';
import tiktok from '../../assets/tiktok-icon.svg';
import { Rating } from '@mui/material';
import { brandLogo, brandName } from '../../api/ApiConfig';
import { NavLink } from 'react-router-dom';

export type ReviewType = {
    name: string,
    date: string,
    ratings: any,
    origin: string,
    destination: string,
    review: string,
    like?: boolean,
    posterLogo?: string | React.ReactNode | any,
    posterName?: string,
    posterResponse?: {
        id: string;
        response: string;
    }
}

// type Response = ;

const Review = ({
    name, date, ratings, origin, destination, review, like = false, posterLogo,
    posterName, posterResponse
}: ReviewType) => {

    const [openShareModal, setOpenShareModal] = useState(false);

    const onClickShareModal = () => {
        setOpenShareModal(prev => !prev);
    }


    return (
        <section>
            <div className='relative p-6 border border-[#e5e7eb] rounded-lg bg-[#F9FAFB] w-full'>
                <div className="flex items-center justify-between space-x-4">
                    <p className='text-base lg:text-xl font-medium capitalize'>
                        {name}
                    </p>

                    <Rating
                        name="ratings"
                        defaultValue={Number(ratings)}
                        readOnly
                    />

                    <p className="lg:block hidden text-gray-500 text-xs lg:text-sm">Reviewed on {date}</p>

                    <div className='lg:block hidden'>
                        <div onClick={onClickShareModal} className="flex space-x-1">
                            <IoShareSocialOutline className='w-5 h-5' />
                            <span className='text-xs lg:text-sm'>Share</span>
                        </div>

                        {
                            openShareModal ? <div className="absolute z-50 top-10 right-0 py-6 px-7 mt-4 flex justify-center items-center bg-white border border-[#f3f4f6] rounded-xl ">
                                <div>
                                    <p className='text-lg font-bold text-[#050d1b]'>Share on</p>

                                    <div className="pt-4 flex items-center space-x-3">
                                        <div className="flex flex-col justify-center items-center">
                                            <img src={facebook} alt="facebook icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Facebook</p>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img src={twitter} alt="twitter icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Twitter</p>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img src={telegram} alt="telegram icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Telegram</p>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img src={tiktok} alt="tiktok icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Tiktok</p>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>

                    <NavLink className="lg:block hidden underline text-orange-500 text-sm font-medium" to="#">View Images</NavLink>
                </div>

                <div className='mt-3 lg:hidden flex justify-between'>
                    <p className=" text-gray-500 text-sm">Reviewed on {date}</p>

                    <div className=''>
                        <div onClick={onClickShareModal} className="flex space-x-1">
                            <IoShareSocialOutline className='w-5 h-5' />
                            <span>Share</span>
                        </div>

                        {
                            openShareModal ? <div className="absolute z-50 top-10 left-0 py-6 px-7 mt-4 flex justify-center items-center bg-white border border-[#f3f4f6] rounded-xl ">
                                <div>
                                    <p className='text-lg font-bold text-[#050d1b]'>Share on</p>

                                    <div className="pt-4 flex items-center space-x-3">
                                        <div className="flex flex-col justify-center items-center">
                                            <img src={facebook} alt="facebook icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Facebook</p>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img src={twitter} alt="twitter icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Twitter</p>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img src={telegram} alt="telegram icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Telegram</p>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img src={tiktok} alt="tiktok icon" className="block w-7 h-7 md:w-8 md:h-8" />
                                            <p className='pt-[5px] text-sm md:text-base'>Tiktok</p>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>


                <div className='mt-3'>
                    <p className='text-lg pt-5 font-semibold text-gray-900'>
                        <span>{origin}</span> to <span>{destination}</span>
                    </p>

                    <p className='text-gray-500 text-sm pt-5 w-full lg:w-[937px]'>
                        {review}
                    </p>
                </div>
            </div>

            {
                posterResponse ?
                    <div className='px-6 py-3 mt-2 bg-[#E5E7EB] rounded-lg'>
                        <div className='flex items-center space-x-3'>
                            <img className='h-[50px]' src={brandLogo} alt="company's logo" />
                            <h4 className='font-bold text-[20px]'>
                                {brandName}
                            </h4>
                        </div>

                        <p className='text-sm mt-[13px] font-medium leading-[21px] w-full lg:w-[937px]'>
                            {posterResponse.response}
                        </p>
                    </div>
                    :
                    null
            }


        </section>
    )
}

export default Review