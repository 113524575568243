import { useCallback, useRef, useState } from 'react';
import pin from '../../assets/pin.svg';
import phoneIcon from '../../assets/phone.svg';
import rightArrow from '../../assets/arrow-right.svg';
import { NavLink } from 'react-router-dom';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { brandName } from '../../api/ApiConfig';



type AvailableLocationProps = {

    name: string,
    address: string,
    phone: string,
    images?: string[] | any,
    image_url?: any,
    latitude?: string,
    longitude?: string,
}

const AvailableLocation = ({ name, address,image_url, phone, images, longitude, latitude }: AvailableLocationProps) => {
    const swiperRef: any = useRef(null);    

    return (
        <div className='h-full flex flex-col justify-between'>
            <div>
                <div>
                    <Swiper
                        ref={swiperRef}
                        loop={true}
                        grabCursor={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}

                        modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                        slidesPerView={1}
                        breakpoints={{
                            500: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                        }}
                    // onSwiper={handleSwiperUpdate}
                    >
                        <div className='relative'>
                            <div className='relative'>
                                {
                                    image_url ?
                                        <img className="rounded-[24px] sm:w-full w-full h-[310px]" src={image_url} alt="" />
                                        :
                                        <img className="rounded-[24px] sm:w-full w-full h-[310px]" src="/location1.png" alt="" />
                                }

                            </div>
                        </div>
                    </Swiper>
                </div>

                <div className="mt-2 flex flex-col justify-between">
                    <div>
                        <h3 className='text-xl md:text-[24px] leading-tight font-semibold text-black'>
                            {brandName} <br /> {name}
                        </h3>

                        <div className='space-y-3 mt-4'>
                            <div className='flex space-x-3 items-center'>
                                <img className='w-[24px] h-[24px]' src={pin} alt="pin svg" />

                                <p className='text-[#061020] text-lg md:text-sm'>
                                    {address}
                                </p>
                            </div>

                            {
                                phone
                                &&
                                <div className='flex space-x-3 items-center'>
                                    <img className='w-[24px] h-[24px]' src={phoneIcon} alt="phone svg" />

                                    <p className='text-[#061020] text-lg md:text-sm'>
                                        {phone}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-[#1A56DB] mt-4 flex space-x-2 text-base'>
                <NavLink to={`https://www.google.com/maps?q=${latitude},${longitude}`}>See on Google Map</NavLink>

                <img src={rightArrow} alt="right arrow" />
            </div>
        </div>
    )
}

export default AvailableLocation