/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { useParams } from "react-router-dom";
import T40Container from "../../../layout/Container";
import Breadcrumbs from "../../../utils/constants/Breadcrumbs";
import CustomButton from "../../../utils/constants/Button";
import { getDynamicColors } from "../../../utils/dynamic/colorUtils";
import BottomDrawer from "../../modals/drawer/BottomDrawer";
import { MdClose } from "react-icons/md";
import SelectSeats from "./steps/SelectSeats";
import Stepper from "./Stepper";
import PassengerDetails from "./steps/PassengerDetails";
import PaymentMethod from "./steps/PaymentMethod";
import TimeConversion from "../../../utils/constants/TimeConversion";
import CurrencyFormat from "../../../utils/constants/CurrencyFormat";
import { SuccessfullyBooked } from "./SuccessfullyBooked";
import { BookingDataResult } from "./BookingProps";
import { useBookingContext } from "../../../context/BookingContext";
import { ACIcon, BatteryIcon, SoundIcon, WifiIcon } from "../../../assets/ExportSvg";

import pin from '../../../assets/pin.svg';
import phoneIcon from '../../../assets/phone.svg';
import rightArrow from '../../../assets/arrow-right.svg';
import { NavLink } from 'react-router-dom';
import { FaArrowLeftLong, FaArrowRightLong, FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import baseURL, { brandName, companyID } from "../../../api/ApiConfig";
import axios from "axios";
import AvailableLocation from "../../locations/AvailableLocation";
import CustomBranches from "../CustomBranches";

interface IBookTicketPageProps { }

interface TripData {
	ride_departure_id: string;
	ride_meta: {
		ride_meta_id: string;
		ride_destination: {
			ride_destination_id: string;
			amount: number;
			arrival_time: string;
			discount: {
				ride_discount_amount: number;
				date_discount_amount: number;
			};
			id: string;
			amount_with_discount: number;
			location: {
				name: string;
				description: string | null;
				address: string | null;
				latitude: number | null;
				longitude: number | null;
				what3word: string | null;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
		};
		departure_time: string;
		comment: string | null;
		business: {
			id: string;
			business_name: string;
			business_email: string;
			phone_number: string;
			address: string;
			postal_code: string | null;
			api_key: string | null;
			abbr_code: string | null;
			logo_url: string;
			is_verified: boolean;
			status: string;
			longitude: number | null;
			latitude: number | null;
			what3word: string | null;
		};
		brand: {
			id: string;
			brand_name: string;
			rating: number;
			status: string;
			logo_url: string;
			url: string;
			customers: {
				bookings: number;
				parcels: number;
			};
			brand_gallery: {
				brand_gallery_id: string;
				image_url: string;
			}[];
		};
		currency: {
			id: string;
			name: string;
			symbol: string;
			code: string;
		};
		vehicle_type: {
			id: string;
			name: string;
			category: string;
			row_col: [number, number];
			cordinates: [number, number][];
			status: string;
			capacity: number;
		};
		ride_departure_location: {
			name: string;
			description: string | null;
			address: string;
			latitude: number;
			longitude: number;
			what3word: string;
			city: {
				id: string;
				name: string;
				code: string | null;
			};
		};
		departure_location: {
			name: string;
			description: string | null;
			address: string;
			latitude: number;
			longitude: number;
			what3word: string;
			city: {
				id: string;
				name: string;
				code: string | null;
			};
		};
		ride_destinations: {
			ride_destination_id: string;
			amount: number;
			arrival_time: string;
			discount: {
				ride_discount_amount: number;
				date_discount_amount: number;
			};
			id: string;
			amount_with_discount: number;
			location: {
				name: string;
				description: string | null;
				address: string | null;
				latitude: number | null;
				longitude: number | null;
				what3word: string | null;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
		}[];
	};
	departure_date: string;
	no_of_seats: number;
	seats: {
		id: string;
		ride_seat_id: string;
		x: number;
		y: number;
		seat_no: number;
		is_available: boolean;
		is_reserved: boolean;
	}[];
	dispatch_status: string;
	id: string;
	ride_code: string | null;
}

interface Passenger {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	gender: string;
	emailAddress: string;
	nokFirstName: string;
	nokLastName: string;
	nokPhoneNumber: string;
}

const breadcrumbs = [
	{ label: "Home", url: "/" },
	{ label: "Select Station", url: "/select-station" },
	{ label: "Book Ticket", url: "/products" },
];

const BookTicketPage: React.FunctionComponent<IBookTicketPageProps> = (
	props
) => {

	const swiperRef: any = React.useRef(null);
	const [getLocations, setGetLocations] = React.useState<any>([]);
	const [loading, setLoading] = React.useState<boolean>(false)

	const slidePrev = React.useCallback(() => {
		if (!swiperRef.current) return;
		swiperRef.current.swiper.slidePrev();
	}, []);

	const slideNext = React.useCallback(() => {
		if (!swiperRef.current) return;
		swiperRef.current.swiper.slideNext();
	}, []);

	const dynamicColors = getDynamicColors();
	const { id } = useParams<{ id: string }>();
	const { bookingData, setBookingData, numberOfTravelers } =
		useBookingContext();

	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const [drawerClasses, setDrawerClasses] = React.useState<string>(
		isDrawerOpen ? "translate-y-0" : "translate-y-full"
	);
	const [stepper, setStepper] = React.useState(1);

	const [selectedTripData, setSelectedTripData] =
		React.useState<TripData | null>(null);

	React.useEffect(() => {
		const storedData = sessionStorage.getItem("selectedTripData");

		if (storedData) {
			try {
				const parsedData: TripData = JSON.parse(storedData);

				// Check if the id matches
				if (parsedData.id === id) {
					setSelectedTripData(parsedData);
				} else {
					// Handle the error (e.g., redirect or show an error message)
					console.error("ID does not match stored data.");
				}
			} catch (error) {
				console.error("Error parsing stored data:", error);
			}
		}
	}, [id]);

	const [selectedSeatIds, setSelectedSeatIds] = React.useState<
		{ id: string; seatNumber: number }[]
	>([]);

	const [passengerDetails, setPassengerDetails] = React.useState<Passenger[]>(
		Array.from({ length: numberOfTravelers }, () => ({
			firstName: "",
			lastName: "",
			phoneNumber: "",
			gender: "",
			emailAddress: "",
			nokFirstName: "",
			nokLastName: "",
			nokPhoneNumber: "",
		}))
	);

	const [extraData, setExtraData] = React.useState<
		BookingDataResult["extra"] | null
	>(null);

	// SuccessfullyBooked state
	const [bookedSuccessfully, setBookedSuccessfully] = React.useState(false);

	const closeDrawer = () => {
		setIsDrawerOpen(false);
		setDrawerClasses("translate-y-full");
	};

	const openDrawer = () => {
		setIsDrawerOpen(true);
		setDrawerClasses("translate-y-0");
	};

	const getLocation = async () => {
		try {
			setLoading(true);
			await axios.get(`${baseURL}/v1/intercity/generals/locations?company_id=${companyID}`)
				// await axios.get(`${baseURL}/v1/intercity/generals/locations`)
				.then((res: any) => {
					setGetLocations(res.data.data);
				})
		} catch (error: any) {

		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		window.scrollTo(0, 0);
		getLocation();
	}, []);

	if (!selectedTripData) {
		return <div>Trip not found</div>;
	}

	// const encodedAddress = encodeURIComponent(
	// 	selectedTripData.ride_meta.business.address.replace(/\s+/g, "+")
	// );

	// const mapUrl = `https://www.google.com/maps/embed/v1/place?q=${encodedAddress}&key=YOUR_GOOGLE_MAPS_API_KEY`;
	return (
		<>
			<div className="bg-[#F6F6F6] pb-10">
				<Breadcrumbs breadcrumbs={breadcrumbs} />
				<T40Container>
					<div className="pt-10 lg:grid grid-cols-1 lg:grid-cols-3 gap-10">
						{/* Main Details  */}
						<div className="lg:col-span-2 space-y-5">
							{/* <h2 className="text-slate-950 text-2xl lg:text-4xl font-semibold lg:font-bold">
								Book Ticket
							</h2> */}

							{/* <div className="w-full h-[233.26px] lg:h-[530px]">
								{selectedTripData.ride_meta.brand.brand_gallery.length > 0 ? (
									<img
										src={
											selectedTripData.ride_meta.brand.brand_gallery[0]
												.image_url
										}
										alt=""
										className="w-full h-full object-cover"
									/>
								) : (
									<img
										src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"
										alt=""
										className="w-full h-full object-cover"
									/>
								)}
							</div> */}

							<h2 className="text-t40Black text-[30px] font-semibold mb-5">Ride details</h2>
							<div className="grid grid-cols-2 lg:grid-cols-3 gap-10 text-slate-950">
								{/* TakeOff Station  */}
								<div className="space-y-2">
									<p
										className="text-xs font-medium uppercase"
										style={{ color: dynamicColors.primary }}>
										TAKEOFF STATION
									</p>
									<div>
										<p className="capitalize text-base font-medium">
											{selectedTripData.ride_meta.departure_location.city.name}
										</p>
										<p className="text-gray-900 text-xs font-normal">
											{selectedTripData.ride_meta.departure_location.name}
										</p>
									</div>
								</div>
								{/* Arrival Station  */}
								<div className="space-y-2">
									<p
										className="text-xs font-medium uppercase"
										style={{ color: dynamicColors.primary }}>
										ARRIVAL STATION
									</p>
									<div>
										<p className="capitalize text-base font-medium">
											{
												selectedTripData.ride_meta.ride_destination.location
													.city.name
											}
										</p>
										<p className="text-gray-900 text-xs font-normal">
											{
												selectedTripData.ride_meta.ride_destination.location
													.name
											}
										</p>
									</div>
								</div>
								{/* DEPARTURE TIME  */}
								<div className="space-y-2">
									<p
										className="text-xs font-medium uppercase"
										style={{ color: dynamicColors.primary }}>
										DEPARTURE TIME
									</p>
									<div>
										<p className="capitalize text-base font-medium">
											<TimeConversion
												timeString={selectedTripData.ride_meta.departure_time}
											/>
										</p>
									</div>
								</div>
								{/* EST. ARRIVAL TIME  */}
								{/* <div className="space-y-2">
									<p
										className="text-xs font-medium uppercase"
										style={{ color: dynamicColors.primary }}>
										EST. ARRIVAL TIME
									</p>
									<div>
										<p className="capitalize text-lg font-medium">
											<TimeConversion
												timeString={
													selectedTripData.ride_meta.ride_destination
														.arrival_time
												}
											/>
										</p>
									</div>
								</div> */}
							</div>

							{/* <h3 className="text-t40Black text-[24px] mt-9 font-semibold mb-5">Amenities </h3> */}
							<div className="flex flex-wrap items-center space-x-3 lg:space-y-0 space-y-4">
								{/* <button className="flex items-center space-x-3 p-3 rounded-md cursor-pointer bg-[#E5E7EB] ">
									<ACIcon />
									<p className="text-base text-t40Black uppercase">Air conditioning</p>
								</button> */}

								{/* <button className="flex items-center space-x-3 p-3 rounded-md cursor-pointer bg-[#E5E7EB] ">
									<BatteryIcon />
									<p className="text-base text-t40Black uppercase">Charging</p>
								</button>

								<button className="flex items-center space-x-3 p-3 rounded-md cursor-pointer bg-[#E5E7EB] ">
									<SoundIcon />
									<p className="text-base text-t40Black uppercase">Obes entertainment</p>
								</button>

								<button className="flex items-center space-x-3 p-3 rounded-md cursor-pointer bg-[#E5E7EB] ">
									<WifiIcon />
									<p className="text-base text-t40Black uppercase"> wifi </p>
								</button> */}
							</div>

							<div className="py-5">
								<hr />
							</div>

							{/* <Heading title="Amenities" /> */}

							{/* Amenities  */}
							{/* <div className="flex flex-wrap items-center gap-3 uppercase text-sm">
								{selectedTripData.amenities.airConditioning && (
									<div className="flex items-center gap-1 px-3 py-1 bg-gray-200 rounded-lg justify-center">
										<ACIcon />
										<p>Air Conditioning</p>
									</div>
								)}
								{selectedTripData.amenities.wifi && (
									<div className="flex items-center gap-1 px-3 py-1 bg-gray-200 rounded-lg justify-center">
										<WifiIcon />
										<p>WIFI</p>
									</div>
								)}
								{selectedTripData.amenities.drinks && (
									<div className="flex items-center gap-1 px-3 py-1 bg-gray-200 rounded-lg justify-center">
										<DrinkIcon />
										<p>DRINKS</p>
									</div>
								)}
								{selectedTripData.amenities.pickUp && (
									<div className="flex items-center gap-1 px-3 py-1 bg-gray-200 rounded-lg justify-center">
										<BusIcon />
										<p>PICKUP</p>
									</div>
								)}
								{selectedTripData.amenities.charging && (
									<div className="flex items-center gap-1 px-3 py-1 bg-gray-200 rounded-lg justify-center">
										<BatteryIcon />
										<p>PICKUP</p>
									</div>
								)}
								{selectedTripData.amenities.music && (
									<div className="flex items-center gap-1 px-3 py-1 bg-gray-200 rounded-lg justify-center">
										<SoundIcon />
										<p>PICKUP</p>
									</div>
								)}
							</div> */}

							{/* <div className="py-5">
								<hr />
							</div> */}

							{/* <Heading title="Location" /> */}

							{/* <iframe
								title="Google Map"
								width="100%"
								height="450"
								frameBorder="0"
								style={{ border: 0 }}
								src={mapUrl}
								allowFullScreen></iframe> */}
							{/* <div>
								<div className="space-y-1">
									<p className="text-gray-400 text-sm font-medium">
										Departure Address
									</p>
									<p className="text-slate-950 text-lg font-medium">
										{selectedTripData.ride_meta.ride_departure_location.name}
									</p>
									<p className="text-gray-900 text-sm font-normal">
										{selectedTripData.ride_meta.business.address}
									</p>
								</div>
							</div> */}

							{/* <div className="py-5">
								<hr />
							</div> */}

							{/* <Heading title="Contact us" />

							<div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
								<div>
									<div>
										<p className="text-gray-400 text-sm font-medium">
											Phone Numbers
										</p>
										<div className="flex flex-wrap gap-2">
											<a
												href={`tel:${selectedTripData.ride_meta.business.phone_number}`}
												className="text-slate-950 text-base font-medium">
												{selectedTripData.ride_meta.business.phone_number}
											</a>
										</div>
									</div>
								</div>

								<div>
									<p className="text-gray-400 text-sm font-medium">Email</p>
									<p className="text-slate-950 text-base font-medium">
										{selectedTripData.ride_meta.business.business_email}
									</p>
								</div>
							</div> */}
						</div>

						{/* Desktop screen Side Action  */}
						<div className="col-span-1 lg:block">
							<div className="bg-white rounded-xl border border-gray-200">
								<div className="p-4 border-b border-gray-200">
									<h4>Price Summary</h4>
								</div>

								<div className="p-4 space-y-3">
									<div className="w-full flex items-center justify-between text-gray-500 text-base">
										<p> Bus Fare </p>
										<p>
											{selectedTripData.ride_meta.currency.symbol}
											<CurrencyFormat
												amount={
													selectedTripData.ride_meta.ride_destination.amount
												}
											/>
										</p>
									</div>
									<div className="w-full flex items-center justify-between text-gray-500 text-base">
										<p>
											Discount
											{/* (
											{
												selectedTripData.ride_meta.ride_destination.discount
													.date_discount_amount
											}
											% ) */}
										</p>
										<p>
											- {selectedTripData.ride_meta.currency.symbol}
											<CurrencyFormat
												amount={
													selectedTripData.ride_meta.ride_destination.discount
														.date_discount_amount
												}
											/>
										</p>
									</div>
									<div className="w-full flex items-center justify-between text-t40Black font-medium">
										<p>Total</p>
										<p>
											{selectedTripData.ride_meta.currency.symbol}
											<CurrencyFormat
												amount={
													selectedTripData.ride_meta.ride_destination
														.amount_with_discount
												}
											/>
										</p>
									</div>
									<div className="pt-3">
										<CustomButton
											onClick={openDrawer}
											type="button"
											text="Next"
											small={false}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-12 ">
						<h3 className='text-t40Black text-[32px] font-bold'>
							Why travel with {brandName}?
						</h3>
						<p className='w-full lg:w-[661px] text-lg text-gray-500 font-medium leading-[27px] mt-4'>
							{brandName} is committed to ensuring your safety to your
							destination and we help you say goodbye to long waits
							and Unreliable Transport service.
						</p>

						<div className='mt-8 '>
						<div className='mt-20 '>
						<Swiper
							ref={swiperRef}
							loop={true}
							grabCursor={true}
							autoplay={{
								delay: 4000,
								disableOnInteraction: false,
							}}
							modules={[Navigation, Autoplay]}
							slidesPerView={1}
							breakpoints={{
								500: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								640: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								768: {
									slidesPerView: 2.2,
									spaceBetween: 10,
								},
								1024: {
									slidesPerView: 3.2,
									spaceBetween: 10,
								},
							}}
						>
							{
									loading ? (
										<p>Loading . . .</p>
									) : (
										getLocations.length > 0 ? (
											getLocations.map((location: any) => (
												<SwiperSlide key={location.id}>
													<AvailableLocation {...location} />
												</SwiperSlide>												
											))
										) : (
											<p>No available location</p>
										)
									)
								}							
						</Swiper>

						<div className="md:hidden pt-6 flex items-center justify-center">
							<div className="flex items-center space-x-[15px]">
								<button onClick={slidePrev} className="p-[10px] rounded-full bg-[#F4F5F5] text-gray-800">
									<FaArrowLeftLong className="text-[#1F2A37]" size={10} />
								</button>

								<button onClick={slideNext} className="p-[10px] bg-white rounded-full border-2 border-[#050d1b] ">
									<FaArrowRightLong className="text-[#1F2A37]" size={10} />
								</button>
							</div>
						</div>
					</div>
						</div>
					</div>
				</T40Container>
			</div>

			{/* Mobile Bottom Bar  */}
			<div className={`${bookedSuccessfully ? "hidden" : ""} lg:hidden z-50 shadow fixed bottom-0 w-full bg-white py-5 px-4`}>
				<div className="flex items-center justify-between gap-10">
					<div className="w-[80%] flex flex-col ">
						<div className="flex items-center gap-2">
							<p className="text-gray-500 text-xs font-normal line-through">
								{selectedTripData.ride_meta.currency.symbol}
								<CurrencyFormat
									amount={selectedTripData.ride_meta.ride_destination.amount}
								/>
							</p>

							{/* Discount Amount  */}
							{/* <div className="px-1 bg-green-100 rounded text-xs text-emerald-900">
								<span>-10%</span>
							</div> */}
						</div>
						<p className="text-slate-950 text-lg font-semibold">
							{selectedTripData.ride_meta.currency.symbol}
							<CurrencyFormat
								amount={
									selectedTripData.ride_meta.ride_destination
										.amount_with_discount
								}
							/>
						</p>
					</div>
					<CustomButton
						small={true}
						onClick={openDrawer}
						type="button"
						text="Next"
					/>
				</div>
			</div>

			<BottomDrawer
				drawerClasses={drawerClasses}
				setDrawerClasses={setDrawerClasses}
				isOpen={isDrawerOpen}
				onClose={closeDrawer}>
				<>
					<div className="z-20 px-4 sticky top-0 bg-white rounded-tl-3xl rounded-tr-3xl flex items-center justify-between py-5 gap-10 lg:gap-16">
						<Stepper step={stepper} setStepper={setStepper} />
						<button
							onClick={closeDrawer}
							className="text-gray-500 hover:text-gray-700">
							<MdClose className="text-3xl text-gray-600" />
						</button>
					</div>

					{stepper === 1 && (
						<SelectSeats
							data={selectedTripData}
							setStepper={setStepper}
							selectedSeatIds={selectedSeatIds}
							setSelectedSeatIds={setSelectedSeatIds}
						/>
					)}
					{stepper === 2 && (
						<PassengerDetails
							data={selectedTripData}
							setStepper={setStepper}
							passengerDetails={passengerDetails}
							setPassengerDetails={setPassengerDetails}
							selectedSeatIds={selectedSeatIds}
							setExtraData={setExtraData}
							setBookingData={setBookingData}
						/>
					)}
					{stepper === 3 && (
						<PaymentMethod
							data={selectedTripData}
							setStepper={setStepper}
							passengerDetails={passengerDetails}
							extraData={extraData}
							bookingData={bookingData}
							setBookedSuccessfully={setBookedSuccessfully}
							closeDrawer={closeDrawer}
						/>
					)}
				</>
			</BottomDrawer>

			{bookedSuccessfully && <SuccessfullyBooked
				passengerDetails={passengerDetails}
			/>}
		</>
	);
};

export default BookTicketPage;

interface HeadingProps {
	title: string;
}

const Heading: React.FunctionComponent<HeadingProps> = (props: any) => {
	const { title } = props;
	return (
		<h3 className="slate-950 text-xl capitalize lg:text-2xl font-semibold">
			{title}
		</h3>
	);
};
