import * as React from "react";
import T40Container from "../layout/Container";
import Breadcrumbs from "../utils/constants/Breadcrumbs";
import { FaDropbox, FaTicketSimple } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi2";
import BookTrip from "../components/selectStation/BookTrip";
import BookTripResultTemplate from "../components/selectStation/BookTripResultTemplate";
import { useSearchParams, useNavigate } from "react-router-dom";
import { formatDate } from "../utils/date";
import baseURL, { brandName, brandURL } from "../api/ApiConfig";
import axios from "axios";
import LoadingSpinner from "../utils/constants/Spinner";
import { useBookingContext } from "../context/BookingContext";
import arrowLeft from "../assets/arrow-circle-left.svg";
import HeroSection from "../components/selectStation/HeroSection";
import CustomServices from "../components/selectStation/CustomServices";
import HowTo from "../components/selectStation/HowTo";
import CustomBranches from "../components/selectStation/CustomBranches";
import CustomReviews from "../components/selectStation/CustomReviews";
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

interface ISelectStationProps { }
interface CityOption {
	value: string;
	label: string;
}

const SelectStation: React.FunctionComponent<ISelectStationProps> = (props) => {
	const navigate = useNavigate();

	const [loadingResult, setLoadingResult] = React.useState(false);
	const [resultPage, setResultPage] = React.useState(1);
	const [searchParams] = useSearchParams();
	const fromCity = searchParams.get("from_city");
	const toCity = searchParams.get("to_city");
	const passengers = searchParams.get("pax");
	const departureDate = searchParams.get("departure_date");	

	const breadcrumbs = [
		{ label: "Home", url: "/" },
		{ label: "Buy Tickets", url: "/select-station" },
	];
	const [activeTab, setActiveTab] = React.useState(0);
	const [lastPage, setLastPage] = React.useState(0);
	const [availableRides, setAvailableRides] = React.useState([]);
	const { numberOfTravelers, setNumberOfTravelers } = useBookingContext();

	const handleTabClick = (tab: number) => {
		setActiveTab(tab);
	};

	// React.useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);

	// Default values
	const defaultSelectedFromCity: CityOption | null = fromCity
		? { value: fromCity, label: fromCity }
		: null;
	const defaultSelectedToCity: CityOption | null = toCity
		? { value: toCity, label: toCity }
		: null;
	const defaultDepartureDate: Date | null = departureDate
		? new Date(departureDate)
		: null;

	// states for book trip
	const [selectedFromCity, setSelectedFromCity] =
		React.useState<CityOption | null>(defaultSelectedFromCity);

	const [selectedToCity, setSelectedToCity] = React.useState<CityOption | null>(
		defaultSelectedToCity
	);
	const [departureDateInput, setDepartureDateInput] =
		React.useState<Date | null>(defaultDepartureDate);
	const [returnDateInput, setReturnDateInput] = React.useState<Date | null>(
		null
	);

	const fetchData = async (page: number) => {
		setLoadingResult(true);
		if (passengers) {
			setNumberOfTravelers(Number(passengers));
		} else {
			setNumberOfTravelers(1);
		}
		try {
			const fromCityParam = selectedFromCity
				? `&from_city=${encodeURIComponent(selectedFromCity.label)}`
				: "";
			const toCityParam = selectedToCity
				? `&to_city=${encodeURIComponent(selectedToCity.label)}`
				: "";
			const departureDateParam = departureDate
				? `&departure_date=${formatDate(departureDateInput)}`
				: "";
			const passenger = passengers ? `&pax=${passengers}` : "";
			const brand = brandURL ? `&brand_url=${brandURL}` : "";			

			const searchURL = `${baseURL}/v1/intercity/connections/find?per_page=10&page=${page}${fromCityParam}${toCityParam}${departureDateParam}${brand}${passenger}`;
		

			const response = await axios.get(searchURL);
			// console.log("hess", response);
			setLastPage(response.data.meta.last_page);
			const available = response.data.data;
			setAvailableRides(available);
			setLoadingResult(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoadingResult(false);
		}
	};

	const onBackClick = () => {
		navigate(-1);
	}

	React.useEffect(() => {
		fetchData(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleResultPage = () => {
		setResultPage((prevResultPage) => {
			const newResultPage = prevResultPage + 1;
			fetchData(newResultPage);
			return newResultPage;
		});
	};


	return (
		<div>
			<Helmet>
				<title>Buy Bus Tickets in Nigeria - Travel by Road with {brandName}</title>
				<meta name='description'
					content={`buy your bus tickets online today with ${brandName} and enjoy an affordable and seamless road trip across intercities in Nigeria`}
				/>
			</Helmet>

			<T40Container className="lg:block hidden ">
				<motion.div
					initial={{
						opacity: 0,
						x: -100,
					}}
					transition={{
						type: "spring",
						stiffness: 300,
						damping: 140,
					}}
					whileInView={{
						opacity: 1,
						x: 0
					}}
					className="flex space-x-6 items-center"
				>
					<div onClick={onBackClick} className="flex space-x-2 items-center">
						<img src={arrowLeft} alt="arrow circle left" />
						<p className="font-semibold text-gray-500">Back</p>
					</div>

					<Breadcrumbs breadcrumbs={breadcrumbs} />
				</motion.div>
			</T40Container>

			<div>
				<HeroSection />
			</div>

			{/*select  */}
			<div className="bg-[#F6F6F6] min-h-[50vh] py-10 -mb-10">
				<T40Container>
					<div className="w-full">
						<div className="mx-auto relative z-30 w-full bg-white rounded-xl flex-col justify-start items-start flex">
							<div className="relative w-full border-b border-gray-200 rounded-t-2xl space-x-4">
								<div className="relative grid lg:flex lg:gap-10 lg:px-4 grid-cols-3 gap-1 place-items-center">
									<Tab
										title="Buy Tickets"
										active={activeTab === 0}
										icon={<FaTicketSimple />}
										onClick={() => handleTabClick(0)}
									/>
									<Tab
										title="Charter Request"
										active={activeTab === 1}
										icon={<HiUserGroup />}
										onClick={() => handleTabClick(1)}
									/>
									<Tab
										title="Send Parcels"
										active={activeTab === 2}
										icon={<FaDropbox />}
										onClick={() => handleTabClick(2)}
									/>
								</div>
							</div>

							<div className="p-4 w-full">
								{activeTab === 0 && (
									<BookTrip
										selectedFromCity={selectedFromCity}
										setSelectedFromCity={setSelectedFromCity}
										selectedToCity={selectedToCity}
										setSelectedToCity={setSelectedToCity}
										departureDateInput={departureDateInput}
										setDepartureDateInput={setDepartureDateInput}
										returnDateInput={returnDateInput}
										setReturnDateInput={setReturnDateInput}
										setAvailableRides={setAvailableRides}
										setLoadingResult={setLoadingResult}
										setLastPage={setLastPage}
									/>
								)}
							</div>
						</div>

						<div className="w-full my-6">
							{activeTab === 0 && (
								<>
									{loadingResult ? (
										<div className="w-full flex flex-col items-center space-y-6">
											<LoadingSpinner />
										</div>
									) : (
										<>
											{availableRides.length > 0 ? (

												<>
													<div className="mt-6">
														<h2 className="leading-[42px] lg:px-0 px-[56px] lg:text-left text-center text-t40Black font-bold text-[28px]">
															<span>{brandName}</span> transport routes, <br className="lg:block hidden" />
															pricing, schedule and tickets
														</h2>

														<p className="py-3 lg:text-left text-center lg:px-0 px-[25px]  text-gray-500 text-base leading-[24px]">
															Buy Affordable {brandName} Bus Tickets Online, Select Destinations, <br /> Find
															Schedules, and Popular Routes.
														</p>
													</div>
													<div className="w-full lg:py-[60px] lg:px-[76px] px-3 py-3  bg-white rounded-[12px] flex flex-col items-center space-y-6">



														{availableRides.map((item, index) => (
															<BookTripResultTemplate
																data={item}
																key={index}
																pax={numberOfTravelers}
															/>
														))}

														<div>
															{resultPage < lastPage && (
																<button
																	onClick={handleResultPage}
																	className="text-zinc-800 bg-white rounded-lg py-3 px-10 border">
																	Load More
																</button>
															)}
														</div>
													</div>
												</>
											) : (
												<div className="w-full py-6 flex flex-col items-center space-y-6">
													<p className="text-center text-red-400">
														Ooops!!! 😢 No results found <br />
														We could not find available rides for your search
													</p>
												</div>
											)}
										</>
									)}
								</>
							)}

							{
								activeTab === 1 && (
									<p className="bg-white rounded-lg py-5 px-3">Coming soon . . .</p>
								)
							}

							{
								activeTab === 2 && (
									<p className="bg-white rounded-lg py-5 px-3">Coming soon . . .</p>
								)
							}							
						</div>


						<CustomServices />

						<HowTo />

						<CustomBranches />

						<CustomReviews />
					</div>
				</T40Container>
			</div>
		</div>
	);
};

export default SelectStation;

interface ITabProps {
	title: string;
	active: boolean;
	icon: any;
	onClick: () => void;
}

const Tab: React.FC<ITabProps> = ({ title, active, onClick, icon }) => (
	<div className="relative w-full lg:w-[20%]">
		<div
			className={`h-20 flex-1 flex items-center justify-center text-center cursor-pointer ${active ? "border-b-4 border-gray-900" : "border-b-4 border-transparent"
				}`}
			onClick={onClick}>
			<div
				className={`flex flex-col lg:flex-row items-center justify-center gap-2.5 ${active ? "text-slate-950" : "text-gray-400"
					}`}>
				{icon}
				<p
					className={`text-sm lg:text-base font-semibold lg:font-bold leading-[15px] lg:leading-tight ${active ? "text-slate-950" : "text-gray-400"
						}`}>
					{title}
				</p>
			</div>
		</div>
	</div>
);
