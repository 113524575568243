import React from 'react';
import HeroArrow from "../../assets/hero-arrow.svg";
import HeroCircle from "../../assets/ticket-loop.svg";
import HeroCircleMd from "../../assets/loop-md.svg";
import TicketHeroMd from "../../assets/custom-hero-section-md.png";
import TicketHero from "../../assets/ticket-hero.png";
import { Headings } from "../../utils/constants/Headings";
import T40Container from '../../layout/Container';
import { getDynamicTexts } from "../../utils/dynamic/textUtils";
import { getDynamicColors } from "../../utils/dynamic/colorUtils";
import { motion } from 'framer-motion';

const HeroSection = () => {

    const dynamicColors = getDynamicColors();
    const dynamicText = getDynamicTexts();

    return (
        <div>
            <div className="bg-gray-900 hidden px-6 lg:px-0">
                <T40Container>
                    <motion.div
                        initial={{
                            y: 50,
                            opacity: 0,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            // damping: 70,
                            duration: 4,
                        }}
                        whileInView={{
                            y: 0,
                            opacity: 1
                        }}
                        className="w-full py-4 flex flex-row items-center justify-center gap-3"
                    >
                        <div className="bg-pink-500 px-2 rounded">
                            <span className="text-white font-black text-xs lg:text-sm">
                                NOTICE
                            </span>
                        </div>

                        <span className="text-xs lg:text-sm font-medium text-blue-50">
                            {dynamicText.ticketTitle}
                        </span>
                    </motion.div>
                </T40Container>
            </div>
            <div className="relative ">
                <div
                    className="w-full lg:h-auto lg:min-h-[627px] py-10 flex bg-no-repeat bg-bottom bg-contain lg:bg-contain lg:bg-right heroBgImage2"
                    style={{
                        backgroundColor: dynamicColors.primary,
                    }}>

                    <img
                        src={HeroCircleMd}
                        alt=""
                        className="absolute z-0 lg:hidden hidden object-cover w-full top-[37%]"
                    />


                    <T40Container>
                        <div className="relative flex flex-col lg:flex-row gap-10">
                            <div className="w-full flex-1 lg:w-[50%] space-y-5 lg:pt-10 xl:pt-20">
                                <div className="mx-auto lg:mx-0 w-fit bg-blue-900 rounded-full px-2 py-2">
                                    <p className="w-full text-white text-[10px] lg:text-sm font-medium leading-[15px]">
                                        Purchase tickets to enjoy premium and affordable travel service
                                    </p>
                                </div>

                                <Headings level="h1" title={dynamicText.ticketHero} />

                                <p className="text-white text-center text-xs lg:text-lg leading-[18px] lg:leading-[27px] font-normal lg:font-medium ">
                                    Easily Buy Bus Tickets, travel seamlessly across interstates in Nigeria
                                </p>

                                <img
                                    src={HeroArrow}
                                    alt="right arrow"
                                    className="animate-bounce absolute left-[40%] hidden lg:block"
                                />
                            </div>

                            <div className="relative flex-1 hidden lg:flex items-center justify-center lg:justify-end">
                                <div className=' flex lg:justify-center justify-center items-center w-full'>
                                    <motion.img
                                        initial={{
                                            scale: 0
                                        }}
                                        whileInView={{
                                            scale: 1
                                        }}
                                        transition={{
                                            type: "spring",
                                            duration: 4,
                                            x: { duration: 2 },
                                            stiffness: 260,
                                            damping: 20
                                        }}
                                        className="md:block hidden" src={TicketHero} alt="picture of people"
                                    />

                                    <motion.img
                                        initial={{
                                            scale: 0
                                        }}
                                        whileInView={{
                                            scale: 1
                                        }}
                                        transition={{
                                            type: "spring",
                                            duration: 4,
                                            x: { duration: 2 },
                                            stiffness: 260,
                                            damping: 20
                                        }}
                                        className="md:hidden block" src={TicketHeroMd} alt="picture of people"
                                    />
                                </div>
                            </div>
                        </div>
                    </T40Container>
                </div>
            </div>
        </div>
    )
}

export default HeroSection